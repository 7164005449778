.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
	padding-top: 30px;
	font-size: 18px;
	/* font-size: 15px; */
}

.contact-main-container{
	/* min-height: calc(100vh - 296px); */
}
.contact-container {
	display: flow;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
	min-height: calc(100vh - 628px);
}



.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contact-email{
	color: var(--link-color)
}