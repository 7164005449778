.experience-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.experience-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.experience-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
    min-height: calc(100vh - 296px - 19px);
}

.experience-subtitle{
    width: 100% !important;
    padding-top: 50px !important;
    font-size: 26px;
    padding-bottom: 0px;
    color: var(--primary-color);
}



.experience-education-school{
    padding-left: 10px;
    font-size: 19px;
    padding-top: 30px;
    padding-bottom: 0px;
    font-weight: bold;
    font-style: italic;
}

.experience-education-school-content{
    padding: 10px, 5px, 10px, 0px;
    font-size: 15px;
    font-style: normal;

    
}

.experience-education-school-link{
    color: var(--link-color);
    text-decoration: none;
}

@media (max-width: 600px) {
	.experience-container {
		padding-top: 60px;
	}

	.experience-project {
		width: calc(100% / 2);
	}
}
